import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  OutboundLink,
} from "@bluefin/components";
import { Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class OrderPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Order Online"}
              tagline={
                <Button
                  primary={true}
                  to={"https://order.online/store/perilla-841059"}
                  as={OutboundLink}
                >
                  DoorDash
                </Button>
              }
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[
                {
                  internal: false,
                  text: "GrubHub",
                  link: "https://www.grubhub.com/restaurant/perilla-vietnamese-cuisine-525-haight-st-san-francisco/1752853",
                },
                {
                  text: "UberEats",
                  link: "https://www.ubereats.com/en-US/san-francisco/food-delivery/perilla-haight/BeRD5FIuSm6mXA7Sb7xk1Q",
                  internal: false,
                },
              ]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
            />
          }
        />
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(title: { eq: "Order Online" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
